.format-container {
    width: 100%;
    background: #272727;
    padding: 15px;
    border-radius: 5px;
    box-shadow: black 0px 0px 1px 1px;
}

.format-header {
    font-size: 1.3em;
    text-align: center;
    border-bottom: 1px solid #619473 ;
    margin-top: 0;
}

.format-radio-container {
    display: flex ;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 15px ;
}

.format-radio-list {
    display: flex ;
    flex-direction: column ;
}

.format-label {
    margin-top: 5px ;
    position: relative;
}

.format-input {
    margin-right: 10px;
    accent-color: #6fb187;
}

.uuid-format-example {
    font-size: small ;
    font-family: "Source Code Pro";
}

