.info-page {
    padding: 30px ;
}

h1, h2{
    margin-bottom: 0px ;
}

p {
    margin-top: 5px ;
    margin-left: 5px ;
}

.generator-link {
    color: #619473 ;
}