.uuid-container {
    height: 5% ;
    min-height: 5% ;
    max-height: 5% ;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 40px ;
    position: relative;
}

.primary-uuid {
    text-align: center;
    margin-right: 25px ;
    margin-left: 15px ;
    font-size: 1.7vw;
    font-family: "Source Code Pro" ;
}

.button-container {
}

.icon-button-primary {
    cursor: pointer;
    padding: 8px 10px;
    border: none;
    background-color: #619473;
    color: #fff;
    border-radius: 5px;
    margin-right: 10px ;
    margin-left: 5px ;
    position: relative;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 10px black;
}

.icon-button-primary img {
    width: 1.2vw;
    height: 1.2vw;
    color: #fff;
    transition: all 0.5s ease-in-out;

}

.icon-button-primary:hover {
    background-color: #2e9e55;
    box-shadow: 0px 4px 15px black;
    #padding: 9px 11px;
    #font-size: 1.1em ;
}

.icon-button-primary:hover img {
    #width: 1.4vw;
    #height: 1.4vw;
}

.button-text {
    font-size: 1em ;
    margin-left: 5px ;
}

/* Log */

.log-container {
    height: 70%;
    max-height: 70%;
    min-height: 70%;
    background-color: #272727 ;
    border-radius: 5px;
    box-shadow: black 0px 0px 1px 1px;
    padding: 15px;
    overflow-y: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    background-color: #272727 ;
    border-radius: 5px;
    padding: 15px ;
}
th {
    font-size: large;
    padding: 8px;
    text-align: left;
    color: white ;
    #border-bottom: 1px solid #619473;
}
td {
    padding: 8px;
    text-align: left;
    color: #c2c2c2 ;
}
